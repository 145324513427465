<template>
  <div class="userinfo-container">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span style="vertical-align: middle;">{{ orderInfo.name }}</span>
    </div>
    <div class="userinfo-box">
      <Loading :show="loading">
        <div data-spm="userinfo">
          <div class="next-loading next-loading-inline sc-khAkCZ jIEYGu">
            <div class="next-loading-wrap">
              <div class="sc-iBaQBe cGFAXW">
                <div class="sc-eggMyH fLwpMi">
                  <h3>用户基本信息</h3>
                  <div style="margin-left: 16px">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      :disabled="!(orderInfo.ldap_id == ''||!orderInfo.ldap_id)"
                      @click="edit"
                    >
                      <span class="next-btn-helper">编辑基本信息</span>
                    </button>
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      :disabled="!orderInfo.is_can_reset_password"
                      @click="reset"
                    >
                      <span class="next-btn-helper">重置密码</span>
                    </button>
                  </div>
                </div>
                <div class="next-row next-row-wrap wind-rc-data-fields">
                  <form-label label="登录名称"> {{ orderInfo.web_name ||'-' }} </form-label>
                  <form-label label="UID"> {{ orderInfo.id }} </form-label>
                  <form-label label="显示名称"> {{ orderInfo.display_name ||'-' }} </form-label>
                  <form-label label="创建时间"> {{ orderInfo.create_time }} </form-label>
                  <form-label label="手机"> {{ orderInfo.phone||'-' }} </form-label>
                  <form-label label="邮箱"> {{ orderInfo.email||'-' }} </form-label>
                  <form-label label="域"> {{ orderInfo.domain_name||'-' }} </form-label>
                  <form-label label="ldap_id"> {{ orderInfo.ldap_id||'-' }} </form-label>
                  <form-label label="备注"> {{ orderInfo.remark||'-' }} </form-label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    </div>
    <tabs-content ref="tabsContent" @create_init="get_info_user" />

    <el-drawer
      ref="drawer"
      size="30"
      custom-class="drawer-warp"
      title="编辑基本信息"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <el-form
          ref="ruleForm"
          label-position="top"
          :model="form"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户登录名称" prop="name">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="显示名称" prop="display_name">
            <el-input v-model="form.display_name" />
          </el-form-item>
          <el-form-item label="手机" prop="phone">
            <el-input v-model="form.phone" />
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email" />
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4}"
              placeholder="请输入内容" />
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            class="next-btn next-medium"
            type="primary"
            @click="submit('ruleForm')"
          >确定</el-button>
          <!-- <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="submit('ruleForm')"
          >
            <span class="next-btn-helper">确定</span>
          </button> -->
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="$refs.drawer.closeDrawer()"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import { details_sub_user, edit_sub_user, reset_password } from '@/api'
import tabsContent from './tabs_content.vue'

export default {
  components: {
    tabsContent
  },
  data () {
    return {
      drawer: false,
      loading: false,
      orderInfo: {},
      tableData: [],
      form: {},
      rules: {
        name: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.get_info_user()
  },

  methods: {
    reset () {
      this.$confirm('是否确认重置密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          account_type: 2,
          user_id: this.orderInfo.id
        }
        reset_password(params).then(res => {
          const msg = `${res.msg}  ${res.data}`
          alert(msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置'
        })
      })
    },
    get_info_user () {
      const params = {
        user_id: this.$route.query.id
      }
      this.loading = true
      details_sub_user(params).then(res => {
        this.loading = false
        this.orderInfo = res.data
        this.$refs.tabsContent.init(res.data.user_type)
      })
    },
    edit () {
      this.drawer = true
      this.form = JSON.parse(JSON.stringify(this.orderInfo))
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            user_id: this.form.id
          }
          edit_sub_user(params).then(res => {
            this.drawer = false
            this.get_info_user()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    previous () {
      this.$router.push('/user')
    }
  }
}
</script>

<style scoped>
.userinfo-container >>> .el-tabs__item {
  font-weight: 400 !important;
}
.userinfo-box {
  padding-top: 25px;
  color: #333;
}
.next-loading {
  width: 100%;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.fLwpMi > h3 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}
h3,
h4 {
  margin-bottom: 7px !important;
  font-size: 14px;
}
.fLwpMi div:first-of-type {
  display: inline-block;
  margin-left: 40px;
}
.cGFAXW {
  margin-bottom: 20px;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.eEgMuO {
  display: flex;
  margin-bottom: 8px;
  width: 50%;
  min-width: 300px;
}
.TJhJs {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.eRLVLq {
  margin-right: 16px;
  width: 164px;
}
</style>
