<template>
  <div>
    <Loading :show="loading">
      <el-tabs
        v-model="be_added_type"
        type="card"
        @tab-click="tabChange"
      >
        <el-tab-pane label="加入的组" name="4">
          <el-tabs v-model="tabs_item_children" type="card" style="margin-left:10px">
            <el-tab-pane label="直接拥有的组" name="1">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary"
                style="margin-right: 8px"
                @click="drawer = true"
              >
                <span class="next-btn-helper">添加到用户组</span>
              </button>
              <el-table
                :header-cell-style="headerCallStyle"
                :data="user_groups_direct_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="用户组名称/显示名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/groups/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
                <el-table-column prop="address" label="操作">
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="remove_group(row)"
                    >
                      <span class="next-btn-helper">移除授权</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="间接拥有的组" name="2">
              <el-table
                :header-cell-style="headerCallStyle"
                :data="group_groups_indirect_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="用户组名称/显示名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/groups/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="拥有的角色" name="2">
          <el-tabs v-model="tabs_item_children" type="card" style="margin-left:10px">
            <el-tab-pane label="直接拥有的角色" name="1">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary"
                style="margin-right: 8px"
                @click="drawer = true"
              >
                <span class="next-btn-helper">新增角色</span>
              </button>
              <el-table
                :header-cell-style="headerCallStyle"
                :data="user_roles_direct_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="角色名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/roles/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
                <el-table-column label="操作">
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="remove_group(row)"
                    >
                      <span class="next-btn-helper">移除授权</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="间接拥有的角色" name="2">
              <el-table
                :header-cell-style="headerCallStyle"
                :data="user_roles_indirect_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="用户组名称/显示名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/roles/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="拥有的权限" name="1">
          <el-tabs v-model="tabs_item_children" type="card" style="margin-left:10px">
            <el-tab-pane label="直接拥有的权限" name="1">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary"
                style="margin-right: 8px"
                @click="drawer = true"
              >
                <span class="next-btn-helper">新增权限</span>
              </button>
              <el-table
                :header-cell-style="headerCallStyle"
                :data="user_permissions_direct_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="权限名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/policies/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" />
                <el-table-column label="操作">
                  <template slot-scope="{row}">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="remove_group(row)"
                    >
                      <span class="next-btn-helper">移除授权</span>
                    </button>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
            <el-tab-pane label="间接拥有的权限" name="2">
              <el-table
                :header-cell-style="headerCallStyle"
                :data="user_permissions_indirect_list"
                border
                style="width: 100%"
                class="table-box"
              >
                <el-table-column
                  prop="name"
                  label="用户组名称/显示名称"
                >
                  <template slot-scope="{ row }">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="href(row, '/policies/handle')"
                    >
                      {{ row.name }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
    </Loading>
    <el-drawer
      ref="drawer"
      custom-class="drawer-warp"
      :size="700"
      :title="tabName[be_added_type]"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <transfer
          ref="transfer"
          label-type="3"
          :list-new="user_list"
          :type="be_added_type"
          @change_transfer="change_transfer"
          @select_change="select_change"
        />
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            :loading="loading_but"
            @click="add"
          >
            <span class="next-btn-helper">确定</span>
          </el-button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="$refs.drawer.closeDrawer()"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import request from '@/utils/request'
import transfer from '@/components/transfer'
import { get_all_sub_user_list, add_permission, del_permission } from '@/api'

export default {
  components: {
    transfer
  },
  data () {
    return {
      drawer: false,
      loading: false,
      loading_but: false,
      user_type: '',
      be_added_type: '4',
      tabs_item_children: '1',
      tabName: {
        '4': '添加用户组',
        '3': '添加用户',
        '2': '新增角色',
        '1': '新增权限'
      },
      user_list: [],
      group_groups_direct_list: [],

      be_added_id: [],
      add_to_id: [],

      //   直接
      user_groups_direct_list: [],
      user_roles_direct_list: [],
      user_permissions_direct_list: [],

      // 间接
      group_groups_indirect_list: [],
      user_roles_indirect_list: [],
      user_permissions_indirect_list: []
    }
  },
  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    }
  },
  mounted () {
    const query = Object.assign({}, this.$route.query, { be_added_type: this.be_added_type })
    this.$router.push({ query })
    this.get_all_list()
  },
  methods: {
    href ({ id, domain_id }, url) {
      this.$router.push({
        path: url,
        query: {
          id,
          domain_id,
          be_added_type: this.$route.query.be_added_type
        }
      })
    },
    init (id) {
      this.user_type = id
      // 用户组
      this.get_groups_direct_list()
      this.get_groups_indirect_list()
      // 角色
      this.get_user_roles_direct_list()
      this.get_user_roles_indirect_list()

      // 权限
      this.get_permissions_direct_list()
      this.get_permissions_indirect_list()
    },
    // 获取所有用户
    async get_all_list () {
      const search = ''
      const params = {
        domain_id: Number(this.$route.query.domain_id)
      }
      const { data } = await get_all_sub_user_list(search, params)
      this.user_list = data
    },

    async get_user_roles_direct_list () {
      try {
        const { data, code } = await request({
          url: '/v1/permission/direct/user_roles',
          method: 'get',
          params: {
            user_id: this.$route.query.id,
            user_type: this.user_type
          }
        })
        this.loading = false
        console.log(data, code)
        this.user_roles_direct_list = data
      } catch (error) {
        this.loading = false
      }
    },
    async get_user_roles_indirect_list () {
      try {
        const { data, code } = await request({
          url: '/v1/permission/indirect/user_roles',
          method: 'get',
          params: {
            user_id: this.$route.query.id,
            user_type: this.user_type
          }
        })
        this.loading = false
        console.log(data, code)
        this.user_roles_indirect_list = data
      } catch (error) {
        this.loading = false
      }
    },

    // 获取直接拥有用户的组
    async get_groups_direct_list (id) {
      try {
        const { data, code } = await request({
          url: '/v1/permission/direct/user_groups',
          method: 'get',
          params: {
            user_id: this.$route.query.id,
            user_type: this.user_type
          }
        })
        this.loading = false
        console.log(data, code)
        this.user_groups_direct_list = data
      } catch (error) {
        this.loading = false
      }
    },
    // 获取直接拥有用户的组
    async get_groups_indirect_list (id) {
      try {
        const { data, code } = await request({
          url: '/v1/permission/indirect/user_groups',
          method: 'get',
          params: {
            user_id: this.$route.query.id,
            user_type: this.user_type
          }
        })
        this.loading = false
        console.log(data, code)
        this.group_groups_indirect_list = data
      } catch (error) {
        this.loading = false
      }
    },

    // 获取直接授予用户的角色
    async get_permissions_direct_list (id) {
      try {
        const { data, code } = await request({
          url: '/v1/permission/direct/user_permissions',
          method: 'get',
          params: {
            user_id: this.$route.query.id,
            user_type: this.user_type
          }
        })
        this.loading = false
        console.log(data, code)
        this.user_permissions_direct_list = data
      } catch (error) {
        this.loading = false
      }
    },
    // 获取间接授予用户的权限
    async get_permissions_indirect_list (id) {
      try {
        const { data, code } = await request({
          url: '/v1/permission/indirect/user_permissions',
          method: 'get',
          params: {
            user_id: this.$route.query.id,
            user_type: this.user_type
          }
        })
        this.loading = false
        console.log(data, code)
        this.user_permissions_indirect_list = data
      } catch (error) {
        this.loading = false
      }
    },
    add () {
      let postData = {}
      this.loading_but = true

      if (this.be_added_type == 4) {
        postData = {
          client_id: 1,
          be_added_type: 3,
          be_added_id: this.be_added_id,
          add_to_type: 4,
          add_to_id: this.add_to_id,
          user_type: this.Ole
        }
      } else if (this.be_added_type == 1) {
        postData = {
          client_id: 1,
          be_added_type: 1,
          be_added_id: this.add_to_id,
          add_to_type: 3,
          add_to_id: this.be_added_id,
          user_type: this.Ole
        }
      } else if (this.be_added_type == 2) {
        postData = {
          client_id: 1,
          be_added_type: 2,
          be_added_id: this.add_to_id,
          add_to_type: 3,
          add_to_id: this.be_added_id,
          user_type: this.Ole
        }
      }

      add_permission(postData).then(res => {
        this.loading_but = false
        if (res.code === 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          })
          this.drawer = false
          this.init(this.user_type)
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          })
        }
      }).catch(() => {
        this.loading_but = false
      })
    },
    change_transfer (list) {
      this.add_to_id = list.map(item => item.id)
    },

    // 自组件选择组触发
    select_change (list) {
      console.log(list, 'be_added_id')
      this.be_added_id = list
      this.Ole = list.map(() => 2)
    },
    add_groups () {},
    tabChange () {
      this.tabs_item_children = '1'
      const query = Object.assign({}, this.$route.query, { be_added_type: this.be_added_type })
      this.$router.push({ query })
    },
    // 移除
    remove_group (row) {
      const data = {
        'client_id': 1, // 应用id
        'grant_id': row.grant_id // 授权信息id
      }
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        del_permission(data).then(res => {
          if (res.code === 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
          this.loading = false
          this.init(this.user_type)
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style>

</style>
